.service {
  padding: 50px 0;
}

h2 {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  color: white;
  padding-bottom: 10px;
}

.container-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}

.contattami {
  padding: 50px 0;
}

.progetti {
  padding: 50px 0;
}

.container-progetti {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.container-project {
  width: 500px;
  position: relative;
}

.container-svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.247);
  padding: 5px;
  border-radius: 100%;
}

.container-svg > svg {
  position: relative;
  width: 90%;
  height: 90%;
  stroke: #d1d1d1;
}

.progetto__thumbnail {
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.progetto__thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
@media screen and (width<750px) {
  .container-card {
    flex-direction: column;
  }

  .container-progetti {
    flex-direction: column;
    align-items: center
  }
  .container-project{
    width: 100%;
  }
}

@media screen and (width<930px){
  .container-card{
    gap: 50px;
  }
}

@media screen and (width<470px){
  .container-card{
    width: 90%;
  }
  .container-progetti{
    width: 90%;
  }
}