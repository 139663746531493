.card{
    width: 320px;
    height: 320px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    color: white;
}

h3{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.container-txt > p, .container-txt > h3{
    text-align: center;
    margin: 0;
}

.img-background{
    height: 60%;
}

.img-background > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-txt{
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.8), #000000 35%, #000000);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

@media screen and (width<470px){
    .card{
        width: 100%;
      }
}
