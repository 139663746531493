button{
    background-color: #2f79a3;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px 20px;
}

button > span{
    font-size: 16px;
    font-weight: 600;
}