hr{
    background-color: #2F79A3;
    width: 65%;
    height: 3px;
    border: none;
    border-radius: 10px;
    margin: 0 auto;
}

.container__progetti{
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    color: white;
    padding: 20px 0;
    gap: 50px;
}

.progetto{
    display: flex;
    justify-content: space-around
}

.info__progetto{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.info__progetto > .title{
    font-size: 28px;
    font-weight: 700;
}

.info__progetto > .descrizione{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.descrizione > span:first-child{
    font-size: 24px;
}

.descrizione > span:last-child{
    width: 100%;
}

.img__progetto{
    aspect-ratio: 16/9;
    width: 40%;
    overflow: hidden;
    border-radius: 15px;
}

.img__progetto > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.progetto ul{
    padding: 0;
    margin: 0;
}

.link .link__svg{
    width: 30px;
    height: 30px;

}

@media screen and (width<1500px){
    .progetto{
        flex-direction: column-reverse;
        align-items: center;
    }

    .img__progetto{
        width: 80%;
    }
    .info__progetto{
        width: 80%;
    }
}

@media screen and (width<650px){
    .descrizione > span:last-child{
        font-size: 14px;
    }
}

@media screen and (width<425px){
    .img__progetto{
        width: 90%;
    }
}