header {
  display: grid;
}

nav {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-img {
  width: 80px;
  height: 80px;
}

.container-img > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

ul {
  display: inline-flex;
  gap: 30px;
}

li {
  color: white;
  list-style-type: none;
  font-size: 24px;
}

.link {
  color: white;
  text-decoration: none;
}

.contact-btn {
  background-color: #2f79a3;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
}

.mobile-header {
  display: none;
}

.container-hambuerger {
  display: none;
  z-index: 90;
}

.open{
    display: block;
    position: fixed;
    height: 100dvh;
    width: 40%;
    top: 0;
    right: 0;
    z-index: 50;
    background-color: #1A1A1A;
    /* animation: .3s ease-out dropdown; */
}


.open > ul{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@keyframes dropdown {
    from{
        height: 0%;
    }
    to{
        height: 100%;
    }
}

@keyframes runAway {
    from{
        height: 100%;
    }
    to{
        height: 0%;
    }
}

@media screen and (width<750px) {
  header > nav > ul,
  .contact-btn {
    display: none;
  }

  .container-hambuerger {
    display: block;
  }

}

@media screen and (width<470px){
  header{
    width: 100%;
  }

  .open{
    width: 50%;
  }
}

@media screen and (width<=320px){
  li{
    font-size: 20px;
  }
}