h1{
    word-wrap: break-word;
}

.hero-section{
    padding-top: 50px;
}

.container__hero{
    width: 70%;
    height: 500px;
    border-radius: 40px;
    margin: 0 auto;
    position: relative;
}
.background{
    background-color: #2F79A3;
    width: 100%;
    height: 100%;
    transform: skew(5deg);
    border-radius: 40px;
}

.main{
    position: absolute;
    top: 0;
    display: inline-flex;
    justify-content: space-evenly;
}

.container-text{
    width: 40%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.bio{
    display: flex;
    flex-direction: column;
}

.bio>p{
    margin: 0;
    font-size: 16px;
}

.bio span{
    font-weight: bold;
}

.navigation-links{
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    display: inline-block;
    height: min-content;
    margin: 40px 0 0 0;
}

.navigation-links > ul{
    display: inline-flex;
    gap: 25px;
    margin: 0;
    padding: 5px 10px;
}


.navigation-links li{
    width: 30px;
    height: 30px;
}

.navigation-links svg{
    width: 30px;
    height: 30px;
    fill: white;
}


.container-cartoon{
    display: block;
    width: 400px;
    height: 500px;
    overflow: hidden;
}

.container-cartoon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (width<1200px) {
    .main{
        justify-content: center;
    }
}
@media screen and (width<1060px) {
    .main{
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .container-cartoon{
        height: 80%;
    }

    .container-cartoon img{
        object-fit: contain;
    }

    .container-text{
        width: 100%;
        align-items: center
    }

    .navigation-links{
        margin: 0;
    }

    .bio{
        display: none;
    }
}

@media screen and (width<590px){
    .container-cartoon{
        width: 100%;
    }
}

@media screen and (width<430px) {
    .container-text{
        width: 80%;
    }
}