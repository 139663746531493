.container__siti {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  gap: 50px;
}

.container__siti > .container__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container__siti h3 {
    color: white;
}



.container__siti button {
    display: inline-block;
}

.container__meme {
  aspect-ratio: 16/9;
  width: 50%;
  overflow: hidden;
  border-radius: 20px;
}

.container__meme > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


@media screen and (width<770px){
  .container__meme{
    width: 80%;
  }
}