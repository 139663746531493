.chisono {
  width: 70%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.periodo {
  display: flex;
  gap: 50px;
}

.periodo > .periodo__img {
  aspect-ratio: 1/1;
  width: 40%;
  overflow: hidden;
  border-radius: 15px;
}

.periodo__img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.periodo__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  color: white;
}

.periodo__info * {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.periodo__info h3 {
  text-align: start;
}

.periodo__info ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.periodo__info a {
  text-decoration: none;
}

.periodo__info li {
  font-size: 20px;
}

.periodo__info h4 {
  font-size: 24px;
}

.periodo__info h4 > span {
  font-weight: 500;
  font-size: 18px;
}

.link__progetto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.link__progetto .link__svg {
  display: inline-block;
}

.link__progetto > .link__svg > svg {
  aspect-ratio: 1/1;
  width: 20px;
}

@media screen and (width<850px) {
  .periodo{
    flex-direction: column;
  }
  .periodo > .periodo__img{
    width: 100%;
  }
}

@media screen and (width<600px) {
  .periodo> .periodo__img{
    width: 100%;
  }
  .chisono{
    width: 100%;
  }
  .periodo{
    width: 80%;
  }
}
