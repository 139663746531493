.form-container{
    border: 1px solid red;
    padding: 50px 0;
}

.form{
    margin: 0 auto;
    border-radius: 10px;
    width: 50%;
    padding: 10px 10px;
    background-color: #0C0C0C;
    color: #FFFFFF;
}